import GlideSlideshow from '../glide-slideshow.js';

export default class Slideshow {
  constructor(el) {
    this.el = el;

    this.pswpElement = document.querySelectorAll('.pswp')[0];

    this.options = {
      index: 0,
      mainClass : 'pswp--minimal--dark',
      barsSize : {top:10,bottom:10},
      captionEl : true,
      fullscreenEl : false,
      shareEl : false,
      bgOpacity : 0.65,
      tapToClose : true,
      tapToToggleControls : false,
      history: false
    };

    new GlideSlideshow(this.el);

    this.el.querySelectorAll('[data-pswp-src]').forEach((img, index) => {

      img.addEventListener('click', (e) => {
        e.preventDefault();

        //if is dragging, don't open photoswipe
        if(img.getAttribute('draggable') == 'false')
          return;

        const pswpItems = [];

        this.options.index = index - 1;

        this.el.querySelectorAll('.glide__slide:not(.glide__slide--clone) [data-pswp-src]').forEach((img) => {
          const item = {
            src : img.getAttribute('data-pswp-src'),
            w : img.getAttribute('data-pswp-width'),
            h : img.getAttribute('data-pswp-height')
          };

          pswpItems.push(item);
        });

        const gallery = new PhotoSwipe( this.pswpElement, PhotoSwipeUI_Default, pswpItems, this.options);
        gallery.init();
      });
    });
  }
}

import 'html5-boilerplate/dist/js/vendor/modernizr-3.11.2.min.js';
import 'html5-boilerplate/dist/js/plugins.js';

// Controllers
import Homepage from './controllers/homepage.js';
import GenericPage from './controllers/generic-page.js';
import Itinerary from './controllers/itinerary.js';

import LanguageSwitcher from './language-switcher.js';
/*
 * DEBOUNCE
  var example = debounce(function() {
  }, 500);
 */
function debounce(func, wait, immediate) {
  var timeout;
  return function() {
    var context = this, args = arguments;
    var later = function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
};

/*
 * Add leading zeros
 * https://gist.github.com/endel/321925f6cafa25bbfbde
 */
Number.prototype.pad = function(size) {
  var sign = Math.sign(this) === -1 ? '-' : '';
  return sign + new Array(size).concat([Math.abs(this)]).join('0').slice(-size);
}


/*
 * DOCUMENT READY
 */
new LanguageSwitcher;

$( document ).ready(function() {

  //* Reload pages after 5 minutes inactive
  //Add localstorage reload-timeout
  let mouseMoveTimeout = undefined;
  const timoutReloadMilSec = 300000;
  const searchParams = new URLSearchParams(window.location.search);
  const reloadTimeout = searchParams.get('reload');

  if(localStorage.getItem('reload-timeout') == null)
    if(reloadTimeout != null)
      localStorage.setItem('reload-timeout', true);

  //Timeout reload
  if(localStorage.getItem('reload-timeout')) {
    mouseMoveTimeout = setTimeout(() => {
      location.reload();
    }, timoutReloadMilSec);

    document.addEventListener('mousemove', () => {
      if(mouseMoveTimeout)
        clearTimeout(mouseMoveTimeout);

      mouseMoveTimeout = setTimeout(() => {
        location.reload();
      }, timoutReloadMilSec);
    });
  }

  if(document.querySelectorAll('.ma-accordion').length === 0)
      document.body.classList.add('body-ready');


  if(document.querySelector('[data-go-back]'))
    document.querySelector('[data-go-back]').addEventListener('click', () => {

      if(window.location.href.includes('agenda')) {
        const searchParam = new URLSearchParams(window.location.search);

        if(searchParam.has('place') && document.referrer)
          window.location = '/mapa';
        else
          window.location = '/';
      }
      else if(window.location.href.includes('actividade')) {
        if(document.referrer)
          window.history.back();
        else
          window.location = '/';
      }
      else {
        window.location = '/';
      }

    });



  if(document.querySelector('#homepage'))
    new Homepage;


  //About page
  const aboutPageEl = document.querySelector('#about-page');
  if(aboutPageEl)
    new GenericPage(aboutPageEl, '/about');


  //Info itinerary page
  const infoItineraryPageEl = document.querySelector('#info-page');

  const urlParams = new URLSearchParams(window.location.search);
  const id = urlParams.get('id');

  if(infoItineraryPageEl)
    new GenericPage(infoItineraryPageEl, '/itineraries/'+ id);


  //Itinerary page
  const itineraryPageEl = document.querySelector('#itinerary-page');
  if(itineraryPageEl)
    new Itinerary(
      itineraryPageEl,
      {
        apiKey: ENV.GOOGLE_MAPS_API_KEY,
        v: 'quarterly'
      }
    );

});

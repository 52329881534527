export default class LanguageSwitcher {
  constructor() {
    this.language = undefined;

    if(localStorage.getItem('language'))
      this._setLanguage( localStorage.getItem('language'));
    else
      this._setLanguage( navigator.language.includes('pt') ? 'pt' : 'en', true);

    this._updateLabels();

    document.querySelectorAll('[data-language]').forEach(el => {
      el.addEventListener('click', e => {
        localStorage.setItem('language', el.getAttribute('data-language'));
        location.reload();
      });
    });
  }

  _updateLabels() {
    if(document.querySelector('[data-menu-opt="itineraries"]'))
      document.querySelector('[data-menu-opt="itineraries"]').innerHTML = window.language == 'pt' ? 'Roteiros' : 'Itineraries';

    if(document.querySelector('[data-menu-opt="about"]'))
      document.querySelector('[data-menu-opt="about"]').innerHTML = window.language == 'pt' ? 'Sobre' : 'About';

    if(document.querySelector('[data-page-title="about"]'))
      document.querySelector('[data-page-title="about"]').innerHTML = window.language == 'pt' ? 'Sobre' : 'About';

    if(document.querySelector('[data-menu-opt="language"]'))
      document.querySelector('[data-menu-opt="language"]').innerHTML = window.language == 'pt' ? 'Língua ' : 'Language ';

  }

  _setLanguage(newLanguage) {
    this.language = newLanguage;

    window.language = this.language;

    localStorage.setItem('language', this.language);

    if(document.querySelector('[data-current-language]'))
      document.querySelector('[data-current-language]').innerHTML = this.language;

    const languageUpdateEvent = new Event('language-update');
  }
}

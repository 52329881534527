import Slideshow from '../components/slideshow.js';

class GenericPage {
  constructor(pageEl, endpoint) {
    this.pageEl = pageEl;
    this.endpoint = endpoint;

    this.converter = new showdown.Converter();

    fetch(window.ENV.API_BASE_PATH + this.endpoint + '?populate=*&locale='+ window.language)
      .then(response => response.json())
      .then(data => {
        this._addData(data.data);
      })
      .catch(error => console.error('Erro:', error));

  }

  _addData(pageData) {
    if(pageData.attributes.color) {
      document.querySelectorAll('.bg-color--itinerary').forEach(el => el.classList.remove('bg-color--itinerary'));
      document.querySelectorAll('.bg-color--itinerary-light').forEach(el => el.classList.remove('bg-color--itinerary-light'));
    }

    if(pageData.attributes.description && this.pageEl.querySelector('[data-page-description]'))
      this.pageEl.querySelector('[data-page-description]').innerHTML = this.converter.makeHtml(pageData.attributes.description);

    if(pageData.attributes.images.data?.length > 0 && this.pageEl.querySelector('[data-page-slideshow]')) {
      if(pageData.attributes.images.data?.length == 1) {
        this.pageEl.querySelector('[data-page-slideshow]').innerHTML = this._getImageHtml(pageData.attributes.images.data[0]);
        this._initPhotoSwipe(this.pageEl.querySelector('[data-page-slideshow] [data-pswp-src]'));
      } else {
        this.pageEl.querySelector('[data-page-slideshow]').innerHTML = this._getSlideshowHtml(pageData.attributes.images.data);
        new Slideshow(this.pageEl.querySelector('[data-page-slideshow] .ma-slideshow'));
      }
    }

    if(pageData.attributes.name) {
      if(document.querySelector('[data-title]'))
        document.querySelector('[data-title]').innerHTML = pageData.attributes.name;
      if(this.pageEl.querySelector('[data-itinerary-title]'))
        this.pageEl.querySelector('[data-itinerary-title]').innerHTML = pageData.attributes.name;
    }

    if(pageData.attributes.color && document.querySelector('[data-color]')) {
      document.querySelector('[data-color]').style.backgroundColor = pageData.attributes.color;
      document.body.style.backgroundColor = pageData.attributes.color+'cf'; // cf - change hex color opacity
    }

    if(pageData.attributes.text_color && document.querySelector('[data-color]')) {
      const textColor = pageData.attributes.text_color == 'light' ? 'white' : 'black';

      document.querySelector('[data-color]').style.color = textColor;
      this.pageEl.querySelector('[data-page-description]').style.color = textColor;
      this.pageEl.querySelector('[data-itinerary-title]').style.color = textColor;

      if(pageData.attributes.text_color == 'light')
        document.querySelector('[data-color] .heading__back-home').classList.add('heading__back-home--light');
      else
        document.querySelector('[data-color] .heading__back-home').classList.add('heading__back-home--dark');
    }

  }

  _getSlideshowHtml(imagesData) { //TODO add this code to Utils to avoid repetition
    return `<div class="ma-slideshow" id="ma-slideshow-1">
              <div class="glide" data-glide-slideshow="data-glide-slideshow">
                <div class="glide__track" data-glide-el="track">
                  <ul class="glide__slides">
                  ${imagesData.reduce((acc, val) => acc + (
                    `<li class="glide__slide">
                      <a data-pswp-width="${val.attributes.width}" data-pswp-height="${val.attributes.height}" data-pswp-src="${window.ENV.API_ASSETS_BASE_PATH + val.attributes.url}">
                        <img class="lazyload" data-srcset="${this._getSrcsetImages(val.attributes)}" data-sizes="auto" width="${val.attributes.width}" height="${val.attributes.height}" src="${window.ENV.API_ASSETS_BASE_PATH + val.attributes.formats.thumbnail.url}"/>
                      </a>
                    </li>`
                  ), '')}
                  </ul>
                </div>
                <div class="ma-slideshow__controls glide__arrows" data-glide-el="controls">
                  <button class="glide__arrow glide__arrow--prev" data-glide-dir="&lt;">
                    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 18.8 30.3" style="enable-background:new 0 0 18.8 30.3;" xml:space="preserve">
                      <polygon fill="#2A2827" points="16.3,30.3 18.8,27.5 5.4,15.1 18.8,2.7 16.3,0 0,15.1"></polygon>
                    </svg>
                  </button>
                  <button class="glide__arrow glide__arrow--next" data-glide-dir="&gt;">
                    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 18.8 30.3" style="enable-background:new 0 0 18.8 30.3;" xml:space="preserve">
                      <polygon fill="#2A2827" points="2.5,0 0,2.8 13.4,15.2 0,27.6 2.5,30.3 18.8,15.2"></polygon>
                    </svg>
                  </button>
                </div>
              </div>
            </div>`;
  }

  _getImageHtml(imageData) { //TODO add this code to Utils to avoid repetition
    return `<div class="fullwidth-image">
              <a data-pswp-width="${imageData.attributes.width}" data-pswp-height="${imageData.attributes.height}" data-pswp-src="${window.ENV.API_ASSETS_BASE_PATH + imageData.attributes.url}">
                <img class="lazyload" data-srcset="${this._getSrcsetImages(imageData.attributes)}" data-sizes="auto" width="${imageData.attributes.width}" height="${imageData.attributes.height}" src="${window.ENV.API_ASSETS_BASE_PATH + imageData.attributes.formats.thumbnail.url}"/>
              </a>
            </div>`;
  }

  _getSrcsetImages(imagesData) { //TODO add this code to Utils to avoid repetition
    let srcset = '';

    if(imagesData.formats.thumbnail)
      srcset += `${window.ENV.API_ASSETS_BASE_PATH + imagesData.formats.thumbnail.url} ${imagesData.formats.thumbnail.width}w, `;
    if(imagesData.formats.medium)
      srcset += `${window.ENV.API_ASSETS_BASE_PATH + imagesData.formats.medium.url} ${imagesData.formats.medium.width}w, `;
    if(imagesData.formats.large)
      srcset += `${window.ENV.API_ASSETS_BASE_PATH + imagesData.formats.large.url} ${imagesData.formats.large.width}w, `;

    srcset += `${window.ENV.API_ASSETS_BASE_PATH + imagesData.url} ${imagesData.width}w`;

    return srcset;
  }

  _initPhotoSwipe(el) {//TODO add this code to Utils to avoid repetition - componente igual
    const pswpElement = document.querySelectorAll('.pswp')[0];

    const options = {
      index: 0,
      mainClass : 'pswp--minimal--dark',
      barsSize : {top:10,bottom:10},
      captionEl : true,
      fullscreenEl : false,
      shareEl : false,
      bgOpacity : 0.65,
      tapToClose : true,
      tapToToggleControls : false,
      history: false
    };

    el.addEventListener('click', (e) => {
      e.preventDefault();

      //if is dragging, don't open photoswipe
      if(el.getAttribute('draggable') == 'false')
        return;

      const pswpItems = [];

      options.index = 1;

      const item = {
        src : el.getAttribute('data-pswp-src'),
        w : el.getAttribute('data-pswp-width'),
        h : el.getAttribute('data-pswp-height')
      };

      pswpItems.push(item);

      const gallery = new PhotoSwipe( pswpElement, PhotoSwipeUI_Default, pswpItems, options);
      gallery.init();
    });
  }
}

export default GenericPage;

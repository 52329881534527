export default class Homepage {
  constructor() {
    this.pinnedItineraries = JSON.parse(localStorage.getItem('data-saved-itineraries'));

    this.itinerariesData = undefined;

    this.menuItinerariesEl = document.querySelector('[data-itineraries]');
    this.pinnedItinerariesContainerEl = document.querySelector('[data-saved-itineraries]');

    this._initMenu();
  }

  async _initMenu() {
    this.itinerariesData = await this._addItinerariesMenuOptions();

    this._initAccordions();

    if(this.pinnedItineraries) {
      this.pinnedItineraries.forEach(pinnedItinerary => {
        const itineraryId = 'itinerary-'+ pinnedItinerary[window.language].id;
        document.querySelector('[data-itinerary="'+ itineraryId +'"] .fa-thumbtack').classList.add('.fas');
        document.querySelector('[data-itinerary="'+ itineraryId +'"] .fa-thumbtack').classList.remove('.far');

        this._addPinnedItenerary(itineraryId, pinnedItinerary[window.language]);
      });
    }

    document.querySelectorAll('[data-itinerary]').forEach(el => {
      const itinerary = el.getAttribute('data-itinerary');
      const currentItineraryId = itinerary.replace('itinerary-', '');
      const thumbtackEl = el.querySelector('.fa-thumbtack');

      if(this.pinnedItineraries?.length > 0) {
        let itineraryIsPinned = false;
        for(const pinnedItinerary of this.pinnedItineraries) {
          if(pinnedItinerary[window.language].id == currentItineraryId) {
            itineraryIsPinned = true;
            break;
          }
        }

        if(itineraryIsPinned)
          el.classList.add('pinned');
        else
          el.classList.remove('pinned');
      }

      thumbtackEl.addEventListener('click', e => this._thumbtackClickHandler(e.currentTarget, itinerary));
    });
  }

  _addItinerariesMenuOptions() {
    return fetch(window.ENV.API_BASE_PATH +'/itineraries/?populate=*&locale='+ window.language)
      .then(response => response.json())
      .then(data => {
        const menuOptionsHtml = data.data.reduce((accumulator, itinerary) => {
          const html =
            `<li class="nav-menu__opt" data-itinerary="itinerary-${itinerary.id}" data-color="${itinerary.attributes.color}">
              <div class="strip-color" style="background-color: ${itinerary.attributes.color}"></div>
              <div class="nav-menu__opt-text">
                <a class="nav-menu__opt-link" href="roteiro/?id=${itinerary.id}">${itinerary.attributes.name}</a>
                <span class="nav-menu__opt-pin">
                  <i class="fas fa-thumbtack"></i>
                </span>
              </div>
            </li>`;

          return accumulator + html;
        }, '');

        this.menuItinerariesEl.innerHTML = menuOptionsHtml;

        return data.data;
      })
      .catch(error => console.error('Erro:', error));
  }

  _initAccordions() {
    if(document.querySelectorAll('.ma-accordion').length > 0) {
      document.querySelectorAll('.ma-accordion').forEach((el, i) => {
        let accordion = undefined;
        if(el.getAttribute('id') === 'accordion-languages')
          accordion = new MaAccordion(el, true, null, null, null, null, () => $('#homepage').animate({ scrollTop: document.querySelector('#homepage').clientHeight}, 300));
        else
          accordion = new MaAccordion(el, true);

        if(i == document.querySelectorAll('.ma-accordion').length -1)
          document.body.classList.add('body-ready');
      });
    }
  }




  _addPinnedItenerary(itinerary, data) {
    const pinnedItineraryHtml =
      `<li class="nav-menu__opt pinned bg-color--itinerary-light" data-itinerary-pinned="${itinerary}">
        <div class="strip-color" style="background-color: ${data.color}"></div>
        <div class="nav-menu__opt-text">
          <a class="nav-menu__opt-link" href="roteiro/?id=${data.id}">
            ${data.title}
          </a>
          <span class="nav-menu__opt-pin">
            <i class="fas fa-thumbtack"></i>
          </span>
        </div>
      </li>`;

    this.pinnedItinerariesContainerEl.insertAdjacentHTML('beforeend', pinnedItineraryHtml);

    const thumbtackEl = document.querySelector('[data-itinerary-pinned="'+itinerary+'"] .fa-thumbtack');
    thumbtackEl.addEventListener('click', e => this._thumbtackClickHandler(e.currentTarget, itinerary));
  }

  _removePinnedItenerary(itinerary) {
    document.querySelector('[data-itinerary="'+itinerary+'"]').classList.remove('pinned');
    document.querySelector('[data-itinerary-pinned="'+itinerary+'"]').remove();
  }

  _thumbtackClickHandler(thumbtackEl, itinerary) {
    const optionEl = thumbtackEl.closest('.nav-menu__opt');

    const currentItineraryId = itinerary.replace('itinerary-', '');
    const currentItineraryData = this.itinerariesData.filter(el => el.id == currentItineraryId)[0];

    let itineraries = JSON.parse(localStorage.getItem('data-saved-itineraries')) || [];

    if(optionEl.classList.contains('pinned')) {
      optionEl.classList.remove('pinned');

      itineraries = itineraries.filter(itinerary => itinerary[window.language].id != currentItineraryId);

      localStorage.setItem('data-saved-itineraries', JSON.stringify(itineraries));
      this._removePinnedItenerary(itinerary);
    }
    else {
      optionEl.classList.add('pinned');

      let data = {};
      //current language itinerary
      data[currentItineraryData.attributes.locale] = {
        id: currentItineraryData.id,
        title: currentItineraryData.attributes.name,
        color: currentItineraryData.attributes.color,
      };
      //translated itinerary
      data[currentItineraryData.attributes.localizations.data[0].attributes.locale] = {
        id: currentItineraryData.attributes.localizations.data[0].id,
        title: currentItineraryData.attributes.localizations.data[0].attributes.name,
        color: currentItineraryData.attributes.localizations.data[0].attributes.color,
      };

      itineraries.push(data);
      localStorage.setItem('data-saved-itineraries', JSON.stringify(itineraries));

      this._addPinnedItenerary(itinerary, data[window.language]);
    }
  }

}
